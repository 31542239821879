import { useParams } from '@solidjs/router';
import { Show, onCleanup, onMount } from 'solid-js';
import IconSearchDocument from '~/assets/icons/searchDocument.png';
import { CONTACT_CUSTOMER_SUPPORT, CONTACT_CUSTOMER_SUPPORT_BLURB, RENTER_SCREENING_ID, UNABLE_TO_VERIFY_IDENTITY } from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { GetRentalApplicationStatusUseCase } from '~/use-cases/rental-applications/application-steps/getRentalApplicationStatusUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';

export const TransUnionManualVerification = () => {
  const { t } = useLocalization();
  const params = useParams();

  let pollingInterval: ReturnType<typeof setTimeout>;
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplicationStatus } = useUseCase(GetRentalApplicationStatusUseCase);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
    if (!pollingInterval) {
      pollingInterval = setInterval(() => {
        getRentalApplicationStatus({ id: params.id, password: params.password });
      }, 10000);
    }
  });

  onCleanup(() => {
    if (pollingInterval) {
      clearInterval(pollingInterval);
    }
  });

  return (
    <Show
      when={!isLoadingApplication()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="-mt-10 flex h-section3  w-full flex-col items-center justify-center  px-3 text-center">
        <div class="my-6 text-base font-semibold text-text-level02 md:text-xl">{t(UNABLE_TO_VERIFY_IDENTITY)}</div>
        <img class=" flex" src={IconSearchDocument} />
        <div class="text-xs font-normal text-text-level03 md:text-sm">
          {t(CONTACT_CUSTOMER_SUPPORT)}
          <span class="font-semibold">(833) 458-6338</span>
        </div>
        <div class="text-xs font-normal text-text-level03 md:text-sm">{t(CONTACT_CUSTOMER_SUPPORT_BLURB)}</div>
        <div class="my-9 text-center text-base font-bold text-text-level02 md:text-lg">
          {t(RENTER_SCREENING_ID, { renterID: presentableRentalApplication()?.transUnionScreening?.transunionScreeningId || '' })}
        </div>
      </div>
    </Show>
  );
};
